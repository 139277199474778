import React, { Component } from 'react'

export default class Introduction extends Component {
  render() {
    const isMobile = window.innerWidth <= 500;
    let /*amazonJPG, readJPG,*/ welcomeColor;
    if (isMobile) {
      welcomeColor = '#000';
      // readJPG = 'url(images/SecondCover_Mobile.png)'; 
      // amazonJPG = 'url(images/BuyOnAmazon_Mobile.jpg)';
    } else {
      welcomeColor = '#fff';
      // readJPG = 'url(images/SecondCover.png)'; 
      // amazonJPG = 'url(images/BuyOnAmazon.jpg)';
    }
    const signupHeaderStyle = {
      color: welcomeColor,
      fontSize: "3rem",
    }
    return (
      <div>
        <section id="colorlib-hero" className="js-fullheight" data-section="home">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li style={{backgroundImage: 'url(images/SilentMaidenCover_cropped.png)'}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <h1 style={{color: welcomeColor}}>Welcome to Arhmanah</h1>
                          <h1 style={signupHeaderStyle}>Sign-up to my<br/>Newsletter<br/>&<br/>Get FREE<br/>Exclusive Stories</h1>
                          <p><a className="btn btn-primary btn-learn" href="https://www.subscribepage.io/ujwalvujjininewsletter" target="_blank" rel="noopener noreferrer">Sign-up <i className="icon-book" /></a></p>
                          {/* <p><a className="btn btn-primary btn-learn" href="https://docs.google.com/document/d/15coSa2rnZcdywihM-pWTvoGqE65JAkxQAh6JDBNrF2Q/edit?usp=sharing" target="_blank" rel="noopener noreferrer">View CV<i className="icon-download4" /></a></p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li style={{backgroundImage: amazonJPG}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1>Get your<br/>copy now<br/>on Amazon</h1>
                          <p><a className="btn btn-primary btn-learn" href="https://www.amazon.com/dp/B0BKX2BNPZ" target="_blank" rel="noopener noreferrer">Buy Now <i className="icon-briefcase3" /></a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              {/* <li style={{backgroundImage: readJPG}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1 style={{color: 'black'}}>Read at<br/>Royal Road &<br/>ScribbleHub</h1>
                          <p><a className="btn btn-primary btn-learn" href="https://www.royalroad.com/fiction/44291/the-mystery-of-the-high-maiden-marhahnyahm" target="_blank" rel="noopener noreferrer">Read at RoyalRoad <i className="icon-book" /></a></p>
                          <p><a className="btn btn-primary btn-learn" href="https://www.scribblehub.com/series/307264/the-mystery-of-the-high-maiden-marhahnyahm/" target="_blank" rel="noopener noreferrer">Read at ScribbleHub <i className="icon-book" /></a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        </section>
      </div>
    )
  }
}
