import React, { Component } from 'react';
import Sidebar from './sidebar'
import Introduction from './introduction'
import About from './about'
import Projects from './projects'
// import Blog from './blog'
// import Timeline from './components/timeline'

export default class Home extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
          <Sidebar></Sidebar>
          <div id="colorlib-main">
            <Introduction></Introduction>
            <About></About>
            <Projects></Projects>
            {/* <Blog></Blog> */}
            {/* <Timeline></Timeline> */}
          </div>
        </div>
      </div>
    );
  }
}