import React, { Component } from 'react';
import './App.css';
import LoreWorld from './components/lore_world'
import Home from './components/home'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPatreon } from '@fortawesome/free-brands-svg-icons'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Blog from './components/blog'
// import Timeline from './components/timeline'

const year = new Date().getFullYear();

class App extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div class="flex-wrapper">
          <Router>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/lore_world' element={<LoreWorld />} />
            </Routes>
          </Router>
          <center>
            <footer>
              <div id="icon-footer-container">
                <a href="https://www.facebook.com/ujwalvujjini" target="_blank" rel="noopener noreferrer"><i className="icon-facebook2Large" /></a>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <a href="https://www.instagram.com/tumbmar_official/" target="_blank" rel="noopener noreferrer"><i className="icon-instagramLarge" /></a>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <a href="https://www.patreon.com/ujwalvujjini/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPatreon} size="2x" transform="grow-2 up-1" /></a>
              </div>
              <br />
              <p><a className="btn btn-primary btn-learn" href="https://www.subscribepage.io/ujwalvujjininewsletter" target="_blank" rel="noopener noreferrer">Sign-up to my Newsletter<i className="icon-book" /></a></p>
              {`Copyright © ${year} Ujwal Vujjini`}
            </footer>
          </center>
        </div>
      </div>
    );
  }
}

export default App;