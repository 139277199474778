import React, { Component } from 'react'

export default class Projects extends Component {
  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="projects">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">Work</span>
								<h2 className="colorlib-heading animate-box">Books<br/><br/>The Last Sage Series</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-2 animate-box" data-animate-effect="fadeInLeft">
							<a href="https://www.amazon.com/dp/B0BTM9HPRZ" target="_blank" rel="noopener noreferrer">
								<div className="project" style={{backgroundImage: 'url(images/SilentMaidenCover_Final.jpeg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://www.amazon.com/dp/B0BTM9HPRZ">Silent Maiden</a></h3>
											<span>The Last Sage Book 1</span>
											{/* <p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p> */}
										</div>
									</div>
								</div>
								</a>
							</div>
							<div className="col-md-2 animate-box" data-animate-effect="fadeInRight">
								<a href="https://www.amazon.com/dp/B0BZ5TTVTX" target="_blank" rel="noopener noreferrer">
								<div className="project" style={{backgroundImage: 'url(images/2331500815_smaller.jpg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://www.amazon.com/dp/B0BZ5TTVTX">Toward Divinity</a></h3>
											<span>The Last Sage Book 2</span>
											{/* <p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p> */}
										</div>
									</div>
								</div>
								</a>
							</div>
							{/* <div className="col-md-2 animate-box" data-animate-effect="fadeInTop">
								<a href="https://www.amazon.com/dp/B0BLM14RH3" target="_blank" rel="noopener noreferrer">
								<div className="project" style={{backgroundImage: 'url(images/3568835955.jpg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://www.amazon.com/dp/B0BLM14RH3">Tears of the Land</a></h3>
											<span>The Last Sage Book 3 (Coming Soon)</span>
											<p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p>
										</div>
									</div>
								</div>
								</a>
							</div> */}
							{/* <div className="col-md-4 animate-box" data-animate-effect="fadeInBottom">
								<div className="project" style={{backgroundImage: 'url(images/img-4.jpg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="work.html">Work 04</a></h3>
											<span>Application</span>
											<p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project" style={{backgroundImage: 'url(images/img-5.jpg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="work.html">Work 05</a></h3>
											<span>Graphic, Logo</span>
											<p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project" style={{backgroundImage: 'url(images/img-6.jpg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="work.html">Work 06</a></h3>
											<span>Web Design</span>
											<p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div> */}
						</div>
						<br/>
						<br/>
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<h2 className="colorlib-heading animate-box">Novellas</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-2 animate-box" data-animate-effect="fadeInDown">
							<a href="https://dl.bookfunnel.com/fgvd3ytt9a" target="_blank" rel="noopener noreferrer">
								<div className="project" style={{backgroundImage: 'url(images/JudgesCover.jpg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://dl.bookfunnel.com/fgvd3ytt9a">Judges</a></h3>
											<span>Get Exclusive Copy through BookFunnel</span>
											{/* <p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p> */}
										</div>
									</div>
								</div>
								</a>
							</div>
						</div>
						<br/>
						<br/>
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInRight">
								<h2 className="colorlib-heading animate-box">In-Progress</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-2 animate-box" data-animate-effect="fadeInDown">
							<a href="https://www.royalroad.com/fiction/60749/he-who-conquered-death-an-isekai-litrpg" target="_blank" rel="noopener noreferrer">
								<div className="project" style={{backgroundImage: 'url(images/2141543668.jpg)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://www.royalroad.com/fiction/60749/he-who-conquered-death-an-isekai-litrpg">He who Conquered Death</a></h3>
											<span>Read on Royal Road</span>
											{/* <p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p> */}
										</div>
									</div>
								</div>
								</a>
							</div>
						</div>
						{/* <div className="row">
							<div className="col-md-12 animate-box">
								<p><a href="#" className="btn btn-primary btn-lg btn-load-more">Load more <i className="icon-reload" /></a></p>
							</div>
						</div> */}
					</div>
				</section>
      </div>
    )
  }
}
